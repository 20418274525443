import React from  'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'   

const StartToday = ({ setShowContactModal, setFormName, setFormEmail, formName, formEmail }) => {

    const handleSubmit = (e) => {
        e.preventDefault()
        setShowContactModal(true)
    }

    const handleChange = (e) => {
        if (e.target.name === 'name') {
            setFormName(e.target.value)
        } else {
            setFormEmail(e.target.value)
        }
    }

    return (
        <Container fluid style={{ backgroundColor: '#f2f2f2' }}>
            <Container style={{ paddingTop: '100px',  paddingBottom: '100px' }}>
                <Row className='d-flex align-items-center'>
                    <Col className='text-center'>
                        <div style={{ fontSize: '48px' }} className='nunito'>
                            Wish You had an <em>EASY BUTTON</em> for your IT?
                        </div>
                        <div style={{ fontSize: '28px' }} className='mt-4 varela'>
                            Contact us for a <span style={{ fontWeight: 'bold' }}>FREE</span> consultation
                        </div>
                    </Col>
                    <Col>
                        <Form style={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '10px' }} onSubmit={handleSubmit}>
                            <Container style={{ paddingTop: '60px', paddingBottom: '60px', maxWidth: '550px' }}>
                                <Form.Group >
                                    <Form.Control required value={formName} onChange={handleChange}  type="text" placeholder="Full Name" name="name" style={{ borderRadius: '20px', height: '70px', fontSize: '28px' }}/>
                                </Form.Group>
                                <Form.Group className='mt-4'>
                                    <Form.Control required  value={formEmail} onChange={handleChange}  type="email" placeholder="Email Address" name="email" style={{ borderRadius: '20px', height: '70px', fontSize: '28px' }}/>
                                </Form.Group>
                                <div className='d-flex justify-content-center mt-5'>
                                    <Button size='lg' variant="primary" type="submit" className='shadow-button-green' style={{ width: '275px', height: '55px' }}>
                                        Get Started
                                    </Button>
                                </div>
                            </Container>
                        </Form>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default StartToday
