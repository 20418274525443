import React from 'react';
import { Container } from 'react-bootstrap';
import RocketImage from '../../../images/rocket.png';

const Rocket = () => {
    return (
        <Container fluid className='d-flex'>
            <img src={RocketImage} alt='rocket' />
        </Container>
    )
}

export default Rocket;