import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import officeImage from '../../images/office.jpg'

const Background = ({ setShowContactModal }) => {
    return (
        <Container className='varela mb-5' style={{ paddingTop: '80px', backgroundColor: '#fbfaf8', maxWidth: '1400px' }}>
            <Row style={{ marginLeft: '20px', marginRight: '20px',  backgroundColor: '#fbfaf8' }}>
                <Col xs={12} lg={6} className='' style={{ backgroundColor: 'rgb(88, 33, 73)' }}>
                    <div style={{ fontSize: '52px', fontWeight: 'bold', color: 'white', textAlign: 'center', marginBottom: '5px' }}>Our Backstory</div>
                    <div style={{ backgroundColor: 'rgba(88, 33, 73)', backgroundImage: `url(${officeImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '500px', backgroundPosition: 'center', borderBottomLeftRadius: '120px' }}></div>
                </Col>
                <Col xs={12} lg={6} className='d-flex align-items-center flex-column justify-content-evenly ' style={{ fontSize: '22px', backgroundColor: 'rgb(88, 33, 73)', color: 'white', minHeight: '600px' }}>
                    <div>From our humble beginnings as break-fix IT specialists, we supported small businesses whenever IT issues arose. Over time, we began to notice recurring trends and developed services that take a more proactive approach, aiming to eliminate problems before they occur. This evolution has enabled us to assist more businesses effectively, providing tailored IT solutions that meet each client's unique needs. Today, we proudly offer comprehensive support and innovative technologies to help our clients thrive in an ever-changing digital landscape.</div>
                    <div><Button onClick={()=> setShowContactModal(true)} variant='light' className='shadow-button-grey varela' size='lg' style={{ width: '275px', height: '55px' }}>IT Consultation <ArrowOutwardIcon /></Button></div>
                </Col>
            </Row>
        </Container>
    )
}

export default Background