import React from 'react'
import { Navbar } from 'react-bootstrap'

const Footer = () => {

    return (
        <Navbar style={{ backgroundColor: '#E1DDE1' }}>
            <div style={{ textAlign: 'center', width: '100%', paddingTop: '10px', paddingBottom: '10px' }} className='nunito'>
            &copy; Go Global Technologies 2024
            </div>
        </Navbar>
    )
}

export default Footer