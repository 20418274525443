import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container, Button, Col, Row } from 'react-bootstrap';
import Logo from '.././../images/ggt.png'
import PhoneIcon from '@mui/icons-material/Phone';


const Header = ({ handleScrollToServices, setShowContactModal }) => {
    return (
        <Navbar expand='md' className='nunito' style={{ fontSize: '20px' }}>
            <Container fluid>
                <Navbar.Brand>
                    <Link to="/" className='nav-link'><img src={Logo} style={{ width: '150px', height: 'auto'}} alt='icon'/></Link>
                </Navbar.Brand>
                <Container fluid className='d-none d-md-block'>
                    <Row>
                        <Col fluid className='d-flex flex-grow-1 justify-content-between'>
                            <Nav style={{ whiteSpace: 'nowrap' }}>
                                <Nav.Link>
                                    <Link className='nav-link' to="/services"><PhoneIcon />&nbsp; 813 421 2446</Link>
                                </Nav.Link>
                            </Nav>
                            <Nav style={{ whiteSpace: 'nowrap' }}>
                                <Nav.Link>
                                    <Link className='nav-link' to="/" onClick={() => handleScrollToServices() }>IT Services</Link>
                                </Nav.Link>
                                <Nav.Link>
                                    <Link className='nav-link' to="/about">About Us</Link>
                                </Nav.Link>
                                <Nav.Link>
                                    <Link className='nav-link' to="/contact">Contact</Link>
                                </Nav.Link>
                            </Nav>
                        </Col>
                        <Col className='d-flex align-items-center' xs='auto'>
                            <Container className=''>
                                <Button onClick={() => setShowContactModal(true)} variant='dark' className=''>Let&apos;s Connect!</Button>
                            </Container>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='d-xs-flex d-md-none'>
                    <Nav style={{ textAlign: 'center' }}>
                        <Nav.Link>
                            <Link className='nav-link' to="/" onClick={() => handleScrollToServices() }>IT Services</Link>
                        </Nav.Link>
                        <Nav.Link>
                            <Link className='nav-link' to="/about">About Us</Link>
                        </Nav.Link>
                        <Nav.Link>
                            <Link className='nav-link' to="/contact">Contact</Link>
                        </Nav.Link>
                    </Nav>
                    <Container className='d-flex justify-content-center mt-2'>
                                <Button onClick={() => setShowContactModal(true)} variant='dark' className=''>Let&apos;s Connect!</Button>
                    </Container>
                </Container>
            </Container>
        </Navbar>
    )
}

export default Header;