import React from 'react'
import { Container, Row, Col, Button, ListGroup } from 'react-bootstrap'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import data from './data'

const OurClients = ({ setShowContactModal }) => {
    return (
        <Container style={{ paddingTop: '80px', backgroundColor: '#fbfaf8', maxWidth: '1200px' }}>
            <Row>
                <Col className='text-center d-flex flex-column justify-content-center varela' style={{ fontSize: '40px', fontWeight: 'bold' }}>What Our Clients Appreciate</Col>
                <Col className='d-flex justify-content-center'>
                    <Button onClick={()=> setShowContactModal(true)} variant='primary' className='shadow-button-green varela' size='lg' style={{ width: '275px', height: '55px' }}>IT Consultation <ArrowOutwardIcon /></Button>
                </Col>
            </Row>
            <ListGroup variant='flush' style={{ marginTop: '70px' }}>
                {data.map((data, index) => {
                    return (
                        <ListGroup.Item key={index} style={{ backgroundColor: '#fbfaf8' }} className=''>
                            <Container>
                                <Row className='varela'>
                                    <Col xs={12} lg={6} className='d-flex'>
                                        <div style={{ marginRight: '15px' }} className='d-flex align-items-center'>{data.icon}</div>
                                        <div style={{ fontSize: '28px' }} className='d-flex align-items-center'>{data.title}</div>
                                    </Col>
                                    <Col xs={12} lg={6} className='' style={{ paddingTop: '20px', paddingBottom: '20px' }}>{data.description}</Col>
                                </Row>
                            </Container>
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </Container>
    )
}

export default OurClients