import React from 'react';
import { Container, ListGroup } from 'react-bootstrap';
import ServicePrototype from './ServicePrototype';
import servicesData from './servicesData'

const ServiceTable = ({ serviceTableRef }) => {


  return (
    <Container fluid style={{ backgroundColor: '#fbfaf8', paddingTop: '80px', paddingBottom: '200px' }} className='varela'>
        <Container className='' style={{ maxWidth: '1200px' }}>
            <ListGroup ref={serviceTableRef} variant='flush' style={{ backgroundColor: '#fbfaf8' }}>
                <ListGroup.Item style={{ fontWeight: 'bold', fontSize: '45px', color: 'rgb(53, 52, 64)', backgroundColor: '#fbfaf8' }}>Our Managed IT Services</ListGroup.Item>
                <ListGroup.Item style={{ backgroundColor: '#fbfaf8' }}></ListGroup.Item>
            </ListGroup>
            <ListGroup variant='flush'>
                {servicesData.map((service, index) => {
                    return (
                        <ListGroup.Item key={index} style={{ backgroundColor: '#fbfaf8' }}>
                            <ServicePrototype title={service.name} description={service.description} icon={service.icon} />
                        </ListGroup.Item>
                    )
                })}
            </ListGroup>
        </Container>
    </Container>
  );
};

export default ServiceTable;
