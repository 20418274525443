import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import backgroundImage from '../../../images/desk.jpg';
import personImage from '../../../images/person.jpg';

const ActionTwo = ({ setShowContactModal }) => {
  return (
    <Container fluid className="image-background-two" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="overlay-two"></div>
      <Row className="text-content-two" style={{ maxWidth: '1400px' }}>
        <Col xl={1} className='d-none d-xl-block' ></Col>
        <Col xs={12} md={6} xl={4} style={{ marginTop: '-100px' }} className='d-flex justify-content-center'>
            <img src={personImage} alt="Person" style={{ maxWidth: '360px', height: '380px', borderBottomLeftRadius: '180px' }} />
        </Col>
        <Col xs={12} md={6} xl={6} className='mt-5'>
          <div>
            <div className='nunito' style={{ fontSize: '36px', maxWidth: '800px' }}>Are you a forward thinking business looking for IT Support that compliments your vision?</div>
            <Button onClick={()=> setShowContactModal(true)} variant='light' className='shadow-button-grey varela' size='lg' style={{ marginTop: '30px', marginBottom: '50px', width: '275px', height: '55px' }}>IT Consultation <ArrowOutwardIcon /></Button>
          </div>
        </Col>
        <Col xl={1} className='d-none d-xl-block' ></Col>
      </Row>
    </Container>
  );
}

export default ActionTwo;
