import React from 'react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'
import satisfactionImage from '../../images/satisfaction.png'

const Contact = ({ setShowContactModal, setFormName, setFormEmail, formName, formEmail }) => {

    const handleSubmit = (e) => {
        e.preventDefault()
        setShowContactModal(true)
    }

    const handleChange = (e) => {
        if (e.target.name === 'name') {
            setFormName(e.target.value)
        } else {
            setFormEmail(e.target.value)
        }
    }

    return (
        <Container fluid style={{ backgroundColor: '#fbfaf8', paddingTop: '60px'}}>
            <Row style={{ paddingLeft: '20px', fontWeight: 'bold', fontSize: '28px' }} className='verala'>
                Contact Us
            </Row>
            <Row style={{ paddingLeft: '20px', fontWeight: 'bold', fontSize: '51px' }} className='nunito mt-2 mb-5'>
                We&apos;re here to help...
            </Row>
            <Row className='d-flex justify-content-center mb-5'>
                <Row className='d-flex' style={{ maxWidth: '1300px' }}>
                    <Col className=''>
                        <img src={satisfactionImage} alt='satisfaction' style={{ maxWidth: '500px' }}/>
                    </Col>
                    <Col className='varela d-flex align-items-center'>
                        <div>
                            <div className='mb-3' style={{ fontWeight: 'bold', fontSize: '28px' }}>Get the help you deserve</div>
                            <div className='mb-3' style={{ fontSize: '22px' }}>Allow us to be the easy button for your IT and provide solutions for your common headaches.</div>
                            <div style={{ fontSize: '22px' }}>We&apos;re here to make technology work for your business, period.</div>
                            <br />
                            <div style={{ fontSize: '22px' }}><span style={{ fontWeight: 'bold' }}>Call us: </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;813 421 2446</div>
                            <div className='d-flex' style={{ fontSize: '22px' }}><div style={{ fontWeight: 'bold' }}>Locate us:</div>&nbsp;<div>5277 Baywater Dr <br />Tampa, FL 33615</div></div>
                        </div>
                    </Col>
                </Row>
            </Row>
            <Row className='d-flex align-items-center' style={{ backgroundColor: 'rgb(242, 242, 242)', paddingTop: '100px', paddingBottom: '100px' }}>
                    <Col className='text-center'>
                        <div style={{ fontSize: '48px' }} className='nunito'>
                            Wish You had an <em>EASY BUTTON</em> for your IT?
                        </div>
                        <div style={{ fontSize: '28px' }} className='mt-4 varela'>
                            Contact us for a <span style={{ fontWeight: 'bold' }}>FREE</span> consultation
                        </div>
                    </Col>
                    <Col>
                        <Form style={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '10px' }} onSubmit={handleSubmit}>
                            <Container style={{ paddingTop: '60px', paddingBottom: '60px', maxWidth: '550px' }}>
                                <Form.Group >
                                    <Form.Control required value={formName} name='name' onChange={handleChange} type="text" placeholder="Full Name" style={{ borderRadius: '20px', height: '70px', fontSize: '28px' }}/>
                                </Form.Group>
                                <Form.Group className='mt-4'>
                                    <Form.Control required value={formEmail} name='email' onChange={handleChange} type="email" placeholder="Email Address" style={{ borderRadius: '20px', height: '70px', fontSize: '28px' }}/>
                                </Form.Group>
                                <div className='d-flex justify-content-center mt-5'>
                                    <Button size='lg' variant="primary" type="submit" className='shadow-button-green' style={{ width: '275px', height: '55px' }}>
                                        Get Started
                                    </Button>
                                </div>
                            </Container>
                        </Form>
                    </Col>
                </Row>
        </Container>
    )
}

export default Contact