import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const ServicePrototype = ({ title, description, icon }) => {
  return (
    <Container>
      <Row>
        <Col xs={12} lg={5} className="d-flex align-items-center">
          <Container className="" >
            <Row>
              <Col xs={2} md={1} lg={2} className="d-flex align-items-center">{icon}</Col>
              <Col className="" style={{ fontWeight: 'bold', fontSize: '30px' }}>{title}</Col>
            </Row>
          </Container>
        </Col>
        <Col xs={12} lg={7}>
          <div style={{ paddingTop: '6px', paddingBottom: '6px', fontSize: '18px' }}>{description}</div>
        </Col>
      </Row>
    </Container>
  );
};


export default ServicePrototype;
