import React from 'react'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Container, Row, Col, Button, ListGroup } from 'react-bootstrap'
import pillarData from './pillarData'

const Pillars = ({ setShowContactModal }) => {
    return (
        <Container fluid style={{ backgroundColor: '#fbfaf8' }}>
            <Container >
                <Row style={{ paddingTop: '100px' }}>
                        <Col className='varela text-center' style={{ fontSize: '45px', fontWeight: 'bold', color: 'rgb(53, 52, 64)' }}>Pillars of Excellence</Col>
                        <Col className='d-flex justify-content-center'>
                            <Button onClick={()=> setShowContactModal(true)} variant='primary' className='shadow-button-green varela' size='lg' style={{ width: '275px', height: '55px' }}>IT Consultation <ArrowOutwardIcon /></Button>
                        </Col>
                </Row>
            </Container>
            <Container className='mt-5 varela'>
                <ListGroup variant='flush' style={{ backgroundColor: '#fbfaf8' }}>
                    <ListGroup.Item style={{ backgroundColor: '#fbfaf8' }}></ListGroup.Item>
                    {pillarData.map((pillar, index) => {
                        return (
                            <ListGroup.Item key={index} style={{ backgroundColor: '#fbfaf8' }} >
                                <Row className='d-flex mb-3 mt-3'>
                                    <Col className='d-flex'>
                                        <div className='d-flex align-items-center' style={{ marginRight: '30px' }}>{pillar.icon}</div>
                                        <div className='d-flex align-items-center' style={{ fontSize: '28px' }}>{pillar.title}</div>
                                    </Col>
                                    <Col className='d-flex' style={{ fontSize: '18px' }}>
                                    {pillar.description}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        )
                    })
                    }
                </ListGroup>
            </Container>
        </Container>
    )
}

export default Pillars