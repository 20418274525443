import React from 'react';
import Action from './Action/Action';
import Message from './Message/Message';
import ServiceTable from './ServiceTable/ServiceTable';
import ActionTwo from './ActionTwo/ActionTwo';
import How from './How/How';
import Pillars from './Pillars/Pillars';
import StartToday from './StartToday/StartToday';

const Home = ({ serviceTableRef, setShowContactModal, setFormName, setFormEmail, formName, formEmail }) => {
    return (
        <>
            <Action setShowContactModal={setShowContactModal} />
            <Message />
            <ServiceTable serviceTableRef={serviceTableRef} />
            <ActionTwo setShowContactModal={setShowContactModal}/>
            <How />
            <Pillars setShowContactModal={setShowContactModal}/>
            <StartToday setShowContactModal={setShowContactModal} setFormEmail={setFormEmail} setFormName={setFormName} formName={formName} formEmail={formEmail} />
        </>
    )
}

export default Home;