import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CardsPrototype from './CardsPrototype';
import cardData from './cardData';

const How = () => {
    return (
        <Container fluid style={{ backgroundColor: '#fbfaf8', paddingBottom: '40px' }}>
            <Container>
                <Row style={{ paddingTop: '100px' }}>
                    <Col className='text-center varela' style={{ fontSize: '45px', fontWeight: 'bold', color: 'rgb(53, 52, 64)' }}>Here&apos;s How We Support</Col>
                </Row>
                <Row className='mt-5'>
                    <Col className='text-center varela' style={{ fontSize: '20px' }}>
                        Our top priority is to provide unwavering support for your business. Our committed team works hand-in-hand with you to offer customized IT solutions that boost efficiency, reliability, and security. This helps foster the growth and success of your organization.
                    </Col>
                </Row>
                <Row className='mt-4 row-cols-2'>
                    {cardData.map((card) => {
                        return (
                             <CardsPrototype key={card.title} title={card.title} description={card.description} image={card.image} />
                        )
                    })}
                </Row>
            </Container>
        </Container>
    )
}

export default How;