import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import callCenterImage from '../../images/callcenter.jpg'
import PieChartOutlineRoundedIcon from '@mui/icons-material/PieChartOutlineRounded';


const Banner = () => {
    return (
        <Container fluid className='g-0'>
            <Row className='mt-5'>
                <Col xs={12} lg={6} className='g-0' style={{ backgroundColor: 'rgba(88, 33, 73)', backgroundImage: `url(${callCenterImage})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', minHeight: '400px', backgroundPosition: 'center' }}>
                </Col>
                <Col xs={12} lg={6} style={{ backgroundColor: 'rgba(88, 33, 73)', color: 'white' }} className='g-0'>
                    <Container style={{ maxWidth: '575px', height: '100%', paddingTop: '30px', paddingBottom: '30px' }} className='d-flex flex-column justify-content-around'>
                        <Row >
                            <Col className='text-center varela' style={{ fontWeight: 'bold', fontSize: '40px' }}>
                                Dependable & Available
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col className='d-flex'>
                                <div style={{ width: '60px', height: '60px', backgroundColor: '#65C444', borderRadius: '50%' }} className='d-flex justify-content-center align-items-center p-4'>
                                    <PieChartOutlineRoundedIcon style={{ fontSize: '60px' }} />
                                </div>
                                <div className='varela' style={{ paddingLeft: '20px' }}>
                                    <div style={{ fontSize: '24px', fontWeight: 'bold' }}>Proactive approach to IT</div>
                                    <div style={{ fontSize: '18px' }}>We don’t wait for issues to arise; instead, we proactively monitor your systems with our precise remote monitoring tools. From internet connections to cybersecurity, we ensure that your IT environment is always under control.</div>
                                </div>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col className='d-flex'>
                                    <div style={{ width: '60px', height: '60px', backgroundColor: '#65C444', borderRadius: '50%' }} className='d-flex justify-content-center align-items-center p-4'>
                                        <PieChartOutlineRoundedIcon style={{ fontSize: '60px' }} />
                                    </div>
                                    <div className='varela' style={{ paddingLeft: '20px' }}>
                                        <div style={{ fontSize: '24px', fontWeight: 'bold' }}>Expertise and Resourcefulness</div>
                                        <div style={{ fontSize: '18px' }}>Our team combines deep expertise with exceptional resourcefulness to ensure your IT systems run smoothly. We leverage cutting-edge tools and innovative solutions to proactively address potential issues. From managing internet connections to safeguarding cybersecurity, our comprehensive approach keeps your IT environment secure and efficient.</div>
                                    </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    )
}

export default Banner