import React from 'react'
import customized from '../../../images/customized.png'
import relax from '../../../images/relax.png'
import support from '../../../images/support.png'
import expert from '../../../images/expert.png'
import partners from '../../../images/partners.png'
import star from '../../../images/star.png'
import cost from '../../../images/cost.png'

const size = '80px'

const data = [
    {
        title: "Customized Solutions",
        icon: <img src={customized} alt='customized' style={{ width: size, height: size }} />,
        description: `We understand that every business is unique. That's why we tailor our IT services to meet your specific needs. Whether you require specialized software, unique network configurations, or custom security measures, we deliver solutions that align perfectly with your business goals.`
    },
    {
        title: "Cost-Effective Services",
        icon: <img src={cost} alt='cost' style={{ width: size, height: size }} />,
        description: `We provide top-tier IT services that deliver exceptional value. Our cost-effective solutions ensure you get the best return on your investment. By optimizing your technology infrastructure, we help you reduce costs while maintaining high levels of performance and security.`
    },
    {
        title: "Dedicated Support",
        icon: <img src={support} alt='support' style={{ width: size, height: size }} />,
        description: `Our team is dedicated to providing you with unparalleled support. We are available 24/7 to address any issues and ensure your IT systems run smoothly. With our proactive approach, you can trust that we are always ready to help, no matter the time or day.`
    },
    {
        title: "Proven Expertise",
        icon: <img src={expert} alt='expert' style={{ width: size, height: size }} />,  
        description: `With years of experience and a team of highly skilled professionals, we bring proven expertise to your business. Our track record of successful IT implementations and satisfied clients speaks for itself. Trust us to handle your technology needs with confidence and competence.`
    },
    {
        title: "Trusted Partnerships",
        icon: <img src={partners} alt='partners' style={{ width: size, height: size }} />,
        description: `We believe in building long-term, trusted partnerships with our clients. Our commitment to transparency, reliability, and mutual growth ensures that we work together towards your business success. Partner with us and experience the benefits of a dependable IT ally.`
    },
    {
        title: "No Worries",
        icon: <img src={relax} alt='relax' style={{ width: size, height: size }} />,
        description: `We take the stress out of managing your IT. With our comprehensive services and proactive monitoring, you can focus on your core business while we handle the technology. No worries, no hidden fees, just seamless IT support that keeps your operations running smoothly.`
    },
    {
        title: "Above and Beyond",
        icon: <img src={star} alt='star' style={{ width: size, height: size }} />,
        description: `We pride ourselves on going above and beyond for our clients. Our proactive approach, attention to detail, and commitment to excellence ensure that we not only meet but exceed your expectations. Experience the difference of an IT partner that truly cares about your success.`
    }
]

export default data