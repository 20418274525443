import React from 'react'
import { Container } from 'react-bootstrap'
import Title from './Title'
import Banner from './Banner'
import OurClients from './OurClients/OurClients'
import Background from './Background'
import GetStarted from './GetStarted'

const About = ({ setShowContactModal, setFormEmail, setFormName, formEmail, formName }) => {
    
        return (
            <Container fluid style={{ backgroundColor: '#fbfaf8' }}>
                <Title />
                <Banner />
                <OurClients setShowContactModal={setShowContactModal}/>
                <Background  setShowContactModal={setShowContactModal} />
                <GetStarted setShowContactModal={setShowContactModal} setFormName={setFormName} setFormEmail={setFormEmail} formName={formName} formEmail={formEmail}/>
            </Container>
        )
}

export default About