import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Paragraph from './Paragraph/Paragraph';
import Rocket from './Rocket';

const Action = ( { setShowContactModal } ) => {
    return (
        <Container fluid style={{ backgroundColor: '#fbfaf8' }}>
            <Row>
                <Col xs={12} md={7} xl={6} xxl={5} className=''>
                    <Paragraph setShowContactModal={setShowContactModal} />
                </Col>
                <Col className='d-none d-md-block'>
                    <Rocket />
                </Col>
            </Row>
        </Container>
    )
}

export default Action;