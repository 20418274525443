import React, {useState} from 'react'
import axios from 'axios';
import apiUrl from '../../apiConfig'
import { Container, Row, Col, Button, Form, Modal, Spinner } from 'react-bootstrap'
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';

const ContactModal = ({ show, setShowContactModal, onHide, formName, formEmail, setFormName, setFormEmail, setShowAlert, setAlertMessage, setAlertVariant }) => {
    const [organization, setOrganization] = useState('')
    const [phone, setPhone] = useState('')
    const [message, setMessage] = useState('')
    const [submitting, setSubmitting] = useState(false)

    // useEffect(() => {
    //     if (formName && formEmail) {
    //         setName(formName)
    //         setEmail(formEmail)
    //     }
    // }, [formName, formEmail])

    const handleChange = (e) => {
        if (e.target.name === 'name') {
            setFormName(e.target.value)
        } else if (e.target.name === 'email') {
            setFormEmail(e.target.value)
        } else if (e.target.name === 'organization') {
            setOrganization(e.target.value)
        } else if (e.target.name === 'phone') {
            setPhone(e.target.value)
        } else {
            setMessage(e.target.value)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setSubmitting(true)
        axios({
            method: 'POST',
            url: `${apiUrl}/send`,
            data: {
                name: formName,
                email: formEmail,
                organization,
                phone,
                message
            }
        }).then((res) => {
            console.log(res)
            setAlertMessage('Thank You! Your message has been sent. We will be in touch shortly.')
            setAlertVariant('success')
            setShowContactModal(false)
            setFormName('')
            setFormEmail('')
            setOrganization('')
            setPhone('')
            setMessage('')
            setShowAlert(true)
            setSubmitting(false)
        }).catch(err => {
            console.log(err)
            setAlertMessage('An error occurred. Please try again later.')
            setAlertVariant('danger')
            setShowAlert(true)
            setSubmitting(false)
        })
    }

    const iconSize = 50
    return (
        <Modal show={show} onHide={onHide} size='xl' centered  >
            <Modal.Header closeButton style={{ backgroundColor: 'rgb(242, 242, 242)', borderBottom: 'none' }}></Modal.Header>
            <Modal.Body style={{ backgroundColor: 'rgb(242, 242, 242)'}}> 
                <Container>
                    <Row>
                        <Col className='nunito' style={{ fontWeight: 'bold', fontSize: '28px' }}>Let&apos;s Connect!</Col>
                    </Row>
                    <Row style={{ width: '100%' }} className='d-flex justify-content-center mt-5'>
                        <Row style={{ maxWidth: '750px' }} className='varela'>
                            <Col xs={12} md={4} className='text-center'>
                                <div className='mb-3 mt-3'><LooksOneIcon sx={{ fontSize: iconSize, color: 'rgb(53, 52, 64)' }}/></div>
                                <div>Please fill out our quick and easy form to schedule a call with us at your earliest convenience.</div>
                            </Col>
                            <Col xs={12} md={4} className='text-center'>
                                <div className='mb-3 mt-3'><LooksTwoIcon sx={{ fontSize: iconSize, color: 'rgb(53, 52, 64)' }} /></div>
                                <div>We will take the necessary time to listen carefully and thoroughly understand your unique situation and needs.</div>
                            </Col>
                            <Col xs={12} md={4} className='text-center'>
                                <div className='mb-3 mt-3'><Looks3Icon sx={{ fontSize: iconSize, color: 'rgb(53, 52, 64)' }} /></div>
                                <div>If we determine we&apos;re a good fit to assist you, we'll inform you of the general costs and next steps.</div>
                            </Col>
                        </Row>
                    </Row>
                    <Row className='mt-5 mb-5'>
                        <Col>
                            <Form style={{ borderStyle: 'solid', borderWidth: '1px', borderRadius: '10px' }} onSubmit={handleSubmit}>
                                <Container style={{ paddingTop: '40px', paddingBottom: '40px', paddingLeft: '40px', paddingRight: '40px' }}>
                                <Row style={{ marginBottom: '20px' }}>
                                    <Col xs={12} md={6}>
                                        <Form.Control onChange={handleChange} style={{ borderRadius: '20px', height: '60px', fontSize: '24px'}} type="text" placeholder="Name*" name='name' value={formName} required />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Control onChange={handleChange} style={{ borderRadius: '20px', height: '60px', fontSize: '24px'}} type="text" placeholder="Organization" name='organization' />
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '20px' }}>
                                    <Col xs={12} md={6}>
                                        <Form.Control onChange={handleChange} style={{ borderRadius: '20px', height: '60px', fontSize: '24px'}} type="email" placeholder="Email*" name='email' value={formEmail} required />
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Form.Control onChange={handleChange} style={{ borderRadius: '20px', height: '60px', fontSize: '24px'}} type="tel" placeholder="Phone" name='phone' />
                                    </Col>
                                </Row>
                                <Row style={{ marginBottom: '20px' }}>
                                    <Col>
                                        <Form.Control onChange={handleChange} style={{ borderRadius: '20px', height: '120px', fontSize: '24px'}} as="textarea"  placeholder="Message*" name='message' required />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='d-flex justify-content-center'>
                                        <Button type='submit' style={{ width: '275px', height: '55px', marginTop: '40px' }} variant="primary" size='lg' className='shadow-button-green varela'  onClick={onHide}>
                                            {submitting ? <Spinner animation="border" role="status" /> : 'Submit'}
                                        </Button>
                                    </Col>
                                </Row>
                                </Container>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default ContactModal