import React from 'react'
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import VpnLockOutlinedIcon from '@mui/icons-material/VpnLockOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import TrendingUpOutlinedIcon from '@mui/icons-material/TrendingUpOutlined';


const size = 80

const PillarData = [
    {
        title: <div><span style={{ color: '#65C444' }}>Reliable</span> IT solutions for consistent performance</div>,
        description: `Technology can be unpredictable, but your IT support shouldn't be. With us, you get a reliable partner who ensures your systems are always running smoothly and effectively, giving you peace of mind.`,
        icon: <HealthAndSafetyOutlinedIcon sx={{ fontSize: size }} />
    },
    {
        title: <div><span style={{ color: '#65C444' }}>Efficient</span> processes and quick response times</div>,
        description: 'Efficiency is key to success. We streamline your IT operations and provide prompt support, ensuring your business stays productive and minimizes downtime, so you can focus on what matters most.',
        icon: <AccountTreeOutlinedIcon sx={{ fontSize: size }} />
    },
    {
        title: <div><span style={{ color: '#65C444' }}>Secure</span> measures to protect your data</div>,
        description: `In today's digital world, security is paramount. We offer robust security measures to safeguard your data and infrastructure, ensuring your business is protected from threats and vulnerabilities at all times.`,
        icon: <VpnLockOutlinedIcon sx={{ fontSize: size }} />
    },
    {
        title: <div><span style={{ color: '#65C444' }}>Innovative</span> technology to drive business growth</div>,
        description: `Staying ahead means embracing innovation. We bring you the latest technology and forward-thinking strategies, helping your business stay competitive and drive growth through innovative IT solutions and services.`,
        icon: <TrendingUpOutlinedIcon sx={{ fontSize: size }} />
    }
]

export default PillarData