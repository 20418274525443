import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Callcenter from '../../../images/callcenter.jpg';

const Message = () => {
  return (
    <Container fluid className="image-background varela" style={{ backgroundImage: `url(${Callcenter})` }}>
      <div className="overlay"></div>
      <Row className="justify-content-center align-items-center text-content" style={{ marginBottom: '100px', marginTop: '80px' }}>
        <Col className="text-center" style={{ maxWidth: '1300px' }}>
            <div style={{ fontSize: '22px' }}>We are committed to empowering our clients with innovative, user-friendly technology solutions where we deliver reliable, efficient, and secure IT services that enhance productiviy, ensure continuity, and drive growth.</div>
            <br />
            <br />
          <h1>Welcome to Exceptional IT</h1>
          <div style={{ fontWeight: 'bold', fontSize: '50px' }} className='nunito'>Your peace of mind is our mission.</div>
        </Col>
      </Row>
    </Container>
  );
}

export default Message;




