import React from 'react';
import { Container, Col, Row, Button } from 'react-bootstrap';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';

const Paragraph = ({ setShowContactModal }) => {
    return (
        <Container fluid className='varela ' style={{ marginTop: '120px', marginBottom: '50px' }}>
            <Row className='' style={{ fontSize: '45px', fontWeight: 'bold', color:'#353440' }}>
                <Col xs={0} xl={1} xxl={2}>
                </Col>
                <Col>
                Providing Proficient
                Managed IT Support
                & Help Desk Services
                </Col>
            </Row>
            <Row style={{ fontSize: '18px' }} className='mt-2'>
                <Col xs={0} xl={1} xxl={2}>
                </Col>
                <Col>
                At Go Global Technologies, we are here to provide OUTSTANDING IT SUPPORT for your growing business. Allow us to simplify your IT and provide you with the tools to achieve your business goals faster.
                </Col>
            </Row>
            <Row className='mt-5'>
                <Col xs={0} xl={1} xxl={2}>
                </Col>
                <Col className='d-flex justify-content-center'>
                    <Button variant='primary' className='button-green shadow-button-green' onClick={() => setShowContactModal(true)} style={{ color: 'white' }} size='lg' >IT Consultation <ArrowOutwardIcon /> </Button>
                </Col>
            </Row>
        </Container>
    )
}

export default Paragraph;