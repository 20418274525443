
import Assessment from './../../../images/assessment.png';
import Roadmap from './../../../images/roadmap.png';
import Security from './../../../images/security.png';
import Callcenter from './../../../images/callcenter.png';

const cardData = [
    {
        title: 'IT Assessment',
        description: `We provide a comprehensive IT assessment to identify your organization's strengths and weaknesses. This helps us develop a customized IT strategy that aligns with your business goals.`,
        image: Assessment
    },
    {
        title: 'IT Roadmap',
        description: `We create a detailed IT roadmap that outlines the steps needed to achieve your business goals. We address any immediate issues and plan for future growth.`,
        image: Roadmap
    },
    {
        title: 'Security and Compliance Plan',
        description: `We develop a security and compliance plan to protect your organization from cyber threats. Our team ensures that IT products and practices follow compliance frameworks specific to your business.`,
        image: Security
    },
    {
        title: 'Ongoing Support',
        description: `We provide ongoing support to ensure that your IT systems are running smoothly. Our team is available to address any issues and provide IT solutions that meet your needs.`,
        image: Callcenter
    }
];

export default cardData;