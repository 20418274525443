import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Title = () => {
        
            return (
                <Container style={{ paddingTop: '60px' }} className='d-flex justify-content-center'>
                    <Row style={{ maxWidth: '800px' }}>
                        <Col className=' varela'>
                            <h1 style={{ fontWeight: 'bold', fontSize: '52px', textAlign: 'center' }}>About Us</h1>
                            <p className='mt-4' style={{ fontSize: '24px' }}>We are committed to empowering our clients with innovative, user-friendly technology solutions where we deliver reliable, efficient, and secure IT services that enhance productiviy, ensure continuity, and drive growth.</p>
                        </Col>
                    </Row>
                </Container>
            )
}

export default Title
