import React from 'react';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import LaptopIcon from '@mui/icons-material/Laptop';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import StorageIcon from '@mui/icons-material/Storage';
import LanguageIcon from '@mui/icons-material/Language';
import CloudOutlinedIcon from '@mui/icons-material/CloudOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import TerminalIcon from '@mui/icons-material/Terminal';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';

const size = 50;

const servicesData = [
    {
      "name": "Help Desk Services",
        "icon": <SupportAgentIcon sx={{ fontSize: size }} />,
      "description": "Our Help Desk Services provide expert technical support to quickly resolve your IT issues. We offer remote and onsite assistance to ensure your systems run smoothly, minimizing downtime and enhancing productivity for your business."
    },
    {
      "name": "VOIP Business Phones",
        "icon": <LocalPhoneOutlinedIcon sx={{ fontSize: size }} />,
      "description": "Upgrade to our VOIP Business Phones for clear, reliable communication. Our system offers advanced features like call forwarding, voicemail-to-email, and conference calling, all at a cost-effective rate, ensuring seamless connectivity for your team."
    },
    {
      "name": "Printer Support",
        "icon": <LocalPrintshopOutlinedIcon sx={{ fontSize: size }} />,
      "description": "Our Printer Support services ensure your printing devices are always operational. From setup and configuration to troubleshooting and maintenance, we handle all aspects of printer management to keep your office running efficiently."
    },
    {
      "name": "Hardware Procurement & Repair",
        "icon": <LaptopIcon sx={{ fontSize: size }} />,
      "description": "We manage your hardware needs from procurement to repair. Our team sources the best equipment, handles installation, and provides repair services to ensure your IT infrastructure remains robust and reliable."
    },
    {
      "name": "Mobile Device Management",
        "icon": <TapAndPlayIcon sx={{ fontSize: size }} />,
      "description": "Our Mobile Device Management solutions secure, monitor, and manage your company’s mobile devices. We ensure compliance, protect data, and optimize the performance of your smartphones and tablets, keeping your mobile workforce efficient."
    },
    {
      "name": "Endpoint Management",
        "icon": <StorageIcon sx={{ fontSize: size }} />,
      "description": "With our Endpoint Management services, we secure and manage all your network devices. We ensure your endpoints are updated, protected, and optimized, reducing risks and improving the performance of your IT environment."
    },
    {
      "name": "Network Administration",
        "icon": <LanguageIcon sx={{ fontSize: size }} />,
      "description": "Our Network Administration services maintain and optimize your network infrastructure. We ensure reliable connectivity, monitor performance, and troubleshoot issues to keep your business operations running smoothly and securely."
    },
    {
      "name": "Cloud Administration",
        "icon": <CloudOutlinedIcon sx={{ fontSize: size }} />,
      "description": "Our Cloud Administration services manage your cloud resources efficiently. We handle setup, optimization, and maintenance of cloud platforms, ensuring scalability, security, and cost-effectiveness for your business operations."
    },
    {
      "name": "Cyber Security Administration",
        "icon": <LockOutlinedIcon sx={{ fontSize: size }} />,
      "description": "Our Cyber Security Administration protects your business from digital threats. We implement security measures, monitor for vulnerabilities, and respond to incidents, ensuring your data and systems are secure against cyber attacks."
    },
    {
      "name": "Compliance Administration",
        "icon": <VerifiedOutlinedIcon sx={{ fontSize: size }} />,
      "description": "Our Compliance Administration services ensure your business meets industry regulations. We help you stay compliant with legal standards, manage audits, and implement best practices, reducing the risk of non-compliance penalties."
    },
    {
      "name": "Business Continuity",
        "icon": <WorkOutlineOutlinedIcon sx={{ fontSize: size }} />,
      "description": "Our Business Continuity services prepare your company for unexpected disruptions. We develop and implement plans to ensure your operations can continue seamlessly, minimizing downtime and financial impact during crises."
    },
    {
      "name": "Application Support and Development",
        "icon": <TerminalIcon sx={{ fontSize: size }} />,
      "description": "Our Application Support services ensure your business applications run smoothly. We provide troubleshooting, updates, and maintenance, addressing issues promptly to keep your software performing at its best."
    },
    {
      "name": "Web Services",
        "icon": <WebAssetIcon sx={{ fontSize: size }} />,
      "description": "Our Web Services provide comprehensive website management. From design and development to hosting and maintenance, we ensure your online presence is professional, functional, and secure, enhancing your brand visibility."
    },
    {
      "name": "IT Consulting",
        "icon": <CoPresentOutlinedIcon sx={{ fontSize: size }} />,
      "description": "Our IT Consulting services offer expert advice to optimize your technology strategy. We assess your current IT setup, recommend improvements, and help implement solutions that align with your business goals, driving growth and efficiency."
    }
  ]
  
  export default servicesData;