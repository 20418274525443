import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Container, Alert } from "react-bootstrap";
import { Route, Routes } from "react-router-dom";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import ContactModal from "./Components/ContactModal/ContactModal";

function App() {

  const [showContactModal, setShowContactModal] = useState(false);
  const [formName, setFormName] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVariant, setAlertVariant] = useState('');

  const serviceTableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 10000);

      // Clean up the timer on component unmount or when showAlert changes
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleScrollToServices = () => {
    navigate('/');
    // Use a slight delay to ensure the navigation is complete
    setTimeout(() => {
      serviceTableRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  };


  return (
    <Container fluid className='p-0'>
      <Header handleScrollToServices={handleScrollToServices} setShowContactModal={setShowContactModal} />
      <Routes>
        <Route exact path='/' element={<Home serviceTableRef={serviceTableRef} setShowContactModal={setShowContactModal} setFormName={setFormName} setFormEmail={setFormEmail} formName={formName} formEmail={formEmail}/>}></Route>
        <Route exact path='/about' element={<About setShowContactModal={setShowContactModal} setFormName={setFormName} setFormEmail={setFormEmail} formName={formName} formEmail={formEmail}/> }></Route>
        <Route exact path='/contact' element={<Contact setShowContactModal={setShowContactModal} setFormName={setFormName} setFormEmail={setFormEmail} formName={formName} formEmail={formEmail} />}></Route>
      </Routes>
      <ContactModal setShowAlert={setShowAlert} setAlertMessage={setAlertMessage} setAlertVariant={setAlertVariant} show={showContactModal} setShowContactModal= {setShowContactModal} formName={formName} formEmail={formEmail} setFormName={setFormName} setFormEmail={setFormEmail} onHide={() => setShowContactModal(false)} />
      <Alert style={{ fontSize: '24px' }} className='fixed-alert' show={showAlert} variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>{alertMessage}</Alert>
      <Footer />
    </Container>
  );
}

export default App;
