import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

const CardsPrototype = ({ title, description, image }) => {
    return (
        <Container className='mt-4 card-hover-shadow' style={{ borderStyle: 'solid', borderWidth: '2px', borderRadius: '10px', width: '625px', height: '325px' }} >
            <Row style={{ height: '100%', paddingRight: '25px', paddingLeft: '25px' }} className=''>
                <Col className='d-flex align-items-center d-none d-md-flex'>
                    <img src={image} alt='card-image' style={{ width: '188px', height: 'auto'}} />
                </Col>
                <Col md={7} className='d-flex flex-column justify-content-center'>
                    <Row>
                        <Col className='nunito' style={{ fontSize: '34px', color: '#353440' }}>
                            {title}
                        </Col>
                    </Row>
                    <Row>
                        <Col className='varela' style={{ fontSize: '16px' }}>
                            {description}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default CardsPrototype;